import React from 'react'
import GlobalWrapper from 'components/global-wrapper'
import Hero from 'components/hero'
import Footer from 'components/footer'
import Seo from 'components/seo'
import styled from 'styled-components'
import { color } from 'styles/theme'
// import Breakpoints from 'utils/breakpoints'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  background: ${color.coolGrey100};
`

const App = () => {
  return (
    <GlobalWrapper>
      {/* <Breakpoints /> */}
      <Seo />
      <Wrapper>
        <Hero />
        <Footer />
      </Wrapper>
    </GlobalWrapper>
  )
}

export default App
