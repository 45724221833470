import React, { useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { color } from 'styles/theme'
import debounce from 'lodash/debounce'
import media from 'utils/media-queries'

const rotate = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`

const Div = styled.div`
  user-select: none;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${color.coolGrey100};
  ${media.md`
    height: max(40vw, 300px);
  `}
  @media (orientation: portrait) {
    z-index: 100;
    height: max(40vw, 300px);
  }
`

const CanvasWrapper = styled.div`
  user-select: none;
  width: 25vw;
  height: 25vw;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Canvas = styled.canvas`
  user-select: none;
  position: absolute;
  border-radius: 50%;
  animation: ${rotate} 60s linear infinite;
  z-index: 1;
`

const Background = styled.div`
  user-select: none;
  position: absolute;
  width: max(25vw, 200px);
  height: max(25vw, 200px);
  border-radius: 50%;
  background: ${color.coolGrey200};
`

const Spirograph = () => {
  const plottingCanvasContainer = useRef()
  let plottingCanvas
  let isMounted
  let ratio
  if (typeof window !== 'undefined') {
    ratio = window.devicePixelRatio || 1
  }

  //canvas
  let windowWidth
  let size
  let pctx

  // config
  const spiroColor = color.coolGrey700
  let speed
  let lineWidth

  // seed values
  let randomM
  let randomN
  let f

  // gear values
  let m
  let n
  let gearRadius
  let angle
  let centerX
  let centerY
  let spiroX
  let spiroY

  useEffect(() => {
    isMounted = true
    window.addEventListener('resize', resizeHandler)
    plottingCanvas = plottingCanvasContainer.current
    pctx = plottingCanvas.getContext('2d')
    pctx.lineWidth = lineWidth
    windowWidth = window.innerWidth
    setCanvasSize()
    initSpirograph()
    window.requestAnimationFrame(draw)
    return function cleanup() {
      window.removeEventListener('resize', resizeHandler)
      isMounted = false
    }
  })

  const resizeHandler = debounce(() => {
    // check if window size has actually changed bc of iOS Safari Bug
    if (window.innerWidth !== windowWidth) {
      setCanvasSize()
      initSpirograph()
      windowWidth = window.innerWidth
    } else {
      return
    }
  }, 100)

  const setCanvasSize = () => {
    ratio = window.devicePixelRatio || 1
    const canvasSize = Math.max(window.innerWidth / 4, 200)
    plottingCanvas.width = canvasSize * ratio
    plottingCanvas.height = canvasSize * ratio
    plottingCanvas.style.width = canvasSize + 'px'
    plottingCanvas.style.height = canvasSize + 'px'
    if (ratio > 1) {
      lineWidth = 2 * ratio
      speed = 0.03
    } else {
      lineWidth = 2
      speed = 0.03
    }
  }

  const reduceFraction = (numerator, denominator) => {
    var gcd = function (a, b) {
      return b ? gcd(b, a % b) : a
    }
    gcd = gcd(numerator, denominator)
    return [numerator / gcd, denominator / gcd]
  }

  const delay = t => new Promise(resolve => setTimeout(resolve, t))

  const initSpirograph = () => {
    // calculate size based on window size
    ratio = window.devicePixelRatio
    size = Math.max((window.innerWidth / 9) * ratio, 89 * ratio)
    // clear canvas
    pctx.clearRect(0, 0, plottingCanvas.width, plottingCanvas.height)
    // get seed values
    randomM = Math.floor(Math.random() * (100 - 3)) + 3
    randomN =
      Math.floor(Math.random() * (randomM / 2 - randomM / 10)) +
      Math.floor(randomM / 10 + 1)
    n = reduceFraction(randomN, randomM)[0]
    m = reduceFraction(randomN, randomM)[1]
    gearRadius = n / m
    f = Math.random() * (0.9 - 0.2) + 0.2
    // initial gear values
    angle = 0
    centerX = plottingCanvas.width / 2
    centerY = plottingCanvas.height / 2
    spiroX =
      centerX +
      ((1 - gearRadius) * Math.cos(angle) +
        f * gearRadius * Math.cos((1 / gearRadius - 1) * angle)) *
        size
    spiroY =
      centerY +
      ((1 - gearRadius) * Math.sin(angle) -
        f * gearRadius * Math.sin((1 / gearRadius - 1) * angle)) *
        size
    // plot outer circle
    pctx.beginPath()
    pctx.lineWidth = lineWidth
    pctx.strokeStyle = spiroColor
  }

  const draw = () => {
    if (isMounted === true) {
      // ---------- PLOTTING CANVAS ----------
      // check if spirograph is uncomplete
      if (angle - speed < n * 2 * Math.PI) {
        pctx.beginPath()
        pctx.moveTo(spiroX, spiroY)
        spiroX =
          centerX +
          ((1 - gearRadius) * Math.cos(angle) +
            f * gearRadius * Math.cos((1 / gearRadius - 1) * angle)) *
            size
        spiroY =
          centerY +
          ((1 - gearRadius) * Math.sin(angle) -
            f * gearRadius * Math.sin((1 / gearRadius - 1) * angle)) *
            size
        pctx.lineTo(spiroX, spiroY)
        pctx.stroke()
      } else {
        // if completed, start new spirograph
        delay(1000)
          .then(() => initSpirograph())
          .then(() => window.requestAnimationFrame(draw))
        return
      }
      // ---------- INCREMENT ----------
      angle += speed
      // draw next frame
      window.requestAnimationFrame(draw)
    } else {
      return
    }
  }

  return (
    <Div>
      <CanvasWrapper>
        <Canvas ref={plottingCanvasContainer} />
        <Background />
      </CanvasWrapper>
    </Div>
  )
}

export default Spirograph
