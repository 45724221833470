import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'

import Spirograph from 'components/spirograph'
import { color } from 'styles/theme'
import Link from 'gatsby-link'
import { v } from '../utils/v-size'

import LogoSrc from '../img/logo.svg'

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  background: ${color.coolGrey50};
  a {
    display: inline-block;
    font-style: italic;
    padding: 0 0.2vw;
    background-image: linear-gradient(
      to top,
      transparent,
      transparent 10%,
      rgba(0, 125, 255, 0.15) 10%,
      rgba(0, 125, 255, 0.15) 30%,
      transparent 30%
    );
    text-decoration: none;
    cursor: pointer;
    &:visited {
      color: black;
    }
    &:active {
      color: ${color.blue400};
    }
    &:hover {
      color: white;
      background: ${color.blue400};
    }
  }
`

const HeroBox = styled.div`
  display: grid;
  grid-template-columns: 7fr 5fr;
  z-index: 1;
  ${media.xl`
    grid-template-columns: 7fr 4fr;
  `}
  ${media.md`
    display: flex;
    flex-direction: column;
  `}
  @media (orientation: portrait) {
    display: flex;
    flex-direction: column;
  }
`

const HeroText = styled.div`
  background: ${color.coolGrey50};
  padding: ${v(20, 140)};
  letter-spacing: -1.1px;
  font-size: ${v(20, 38)};
  min-height: calc(100vh - 80px);
  line-height: 1.4;
  border-right: 2px solid black;
  ${media.md`
    border: none;
    border-bottom: 2px solid black;
    min-height: 0;
    letter-spacing: -0.8px;
  `}
  @media (orientation: portrait) {
    border: none;
    border-bottom: 2px solid black;
    min-height: 0;
  }
  p {
    margin: 0;
    margin-bottom: ${v(16, 24)};
  }
`

const Name = styled.span`
  font-weight: bold;
`

const SVGLogo = styled.img`
  z-index: 101;
  width: 56px;
  height: 56px;
  box-sizing: border-box;
  opacity: 0.8;
  ${media.xl`
    width: 40px;
    height: 40px;
  `}
  :hover {
    transform: rotate(-15deg);
  }
  :active {
    transform: scale(1.1) rotate(-15deg);
  }
  transition: transform 200ms ease;
`

const LogoLink = styled(Link)`
  margin-bottom: ${v(20, 40)};
  margin-left: -8px;
  background-image: none !important;
  padding: 0;
  :hover {
    color: black !important;
    background: none !important;
  }
  ${media.md`
    margin-left: -5px;
  `}
`

const Hero = () => {
  return (
    <HeroSection>
      <HeroBox>
        <HeroText>
          <LogoLink to="/" aria-label="Go back to the homepage">
            <SVGLogo src={LogoSrc} alt="Gabriel Schneider Logo" />
          </LogoLink>
          <p>
            <Name>Gabriel Schneider</Name> is a product designer and founder
            based in Berlin.
          </p>
          <p>
            He is currently working on <a href="https://sonia.so">Sonia</a>, an
            AI-powered documentation & coding tool for dentists.
          </p>
          <p>
            In the past, he has designed digital products such as the
            award-winning medical messenger <Link to="/lifetime">LifeTime</Link>
            , the official{' '}
            <Link to="/covid-vaccination-portal">COVID vaccination portal</Link>{' '}
            for Schleswig-Holstein, and the top-rated color palette editor{' '}
            <Link to="/palettte-app">Palettte App</Link>. He has crafted pitch
            decks that secured seven-figure funding rounds and founded the{' '}
            <Link to="/hytide">Hytide</Link> agency, which hosts electronic
            music & art festivals with up to 20k visitors per year.
          </p>
          <p>
            His work has been featured in <em>Tagesthemen</em>,{' '}
            <i>Süddeutsche Zeitung</i>, <i>Welt</i>, <i>Deutsche Startups</i>{' '}
            and other media.
          </p>
          <p>
            Get in touch via <a href="https://twitter.com/gabdorf">Twitter</a>,{' '}
            <a href="https://www.linkedin.com/in/gabrielvschneider/">
              LinkedIn
            </a>{' '}
            or <Link to="/contact">email</Link>.
          </p>
        </HeroText>
        <Spirograph />
      </HeroBox>
    </HeroSection>
  )
}
export default Hero
